
import React from "react";

function MenuItem(props){

    return(

        <div>

            <a href={props.menuItemLink} >
                    <div className='cartDiv cartDivResp'>
                        <div className="carteDivMarge">
                            <img className="carteImageClass"  src={props.menuItemLogo} alt="Nos Formules" />
                        </div>
                        <div className="carteLabelClass">{props.itemTitle}</div>
                    </div>
            </a>
        </div>

    );

}

export default MenuItem